@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300;10..48,400;10..48,500;10..48,600;10..48,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/inter/Inter-Medium.woff2') format('woff2'),
    url('assets/fonts/inter/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/inter/Inter-Regular.woff2') format('woff2'),
    url('assets/fonts/inter/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/inter/Inter-Bold.woff2') format('woff2'),
    url('assets/fonts/inter/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/inter/Inter-SemiBold.woff2') format('woff2'),
    url('assets/fonts/inter/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
    url('assets/fonts/inter/Inter-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

@media screen and (min-width: 1024px) {

    p {
        font-size: 20px;
    }
}

/* List */
.list {
    list-style: none;
    padding-left: 0;
}

/* Nav */
.nav {
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 1rem;
    align-items: center;
    width: 100%;
}

.nav__list {
    width: 100%;
    margin: 0;
}

.nav__item {
    padding: 0.8rem 0;
    border-bottom: 1px solid #eae3c8;
}

.nav__toggler {
    opacity: 0.5;
    transition: box-shadow 0.15s;
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .nav {
        padding: 1rem 15rem;
        display: flex;
        justify-content: space-between;
        /*box-shadow: 0 0 30px 5px #e6ebee;*/
    }

    .nav__toggler {
        display: none;
    }

    .nav__list {
        width: auto;
        display: flex;
        font-size: 1rem;
        max-height: 100% !important;
        opacity: 1 !important;
    }

    .nav__item {
        display: flex;
        align-items: center;
        padding: 0.6rem 1.5rem;
        border: 0;
    }
}

.collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.collapsible--expanded .collapsible__content {
    max-height: 100vh;
    opacity: 1;
}

/* Button */
.btn {
    text-align: center;
    border-radius: 3px;
    width: 150px;
    font-size: 18px;
    font-weight: bold;

    padding: 0.5rem 0.5rem;
    cursor: pointer;
    outline: none;
}

.btn--secondary {
    background: white;
    border: 1px solid lightgrey;
    color: black;
}

.btn--contact {
    text-decoration: none;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    color: black;
}

.btn--load {

    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    color: black;
}

.btn--secondary:hover, .btn--contact:hover {
    background: black;
    color: #fff;
}

/* Grid */
.grid {
    display: grid;
}

@media screen and (min-width: 1024px) {
    .grid--cols-3 {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3rem;
    }
}

/* Image */
.company__image {
    border-radius: 3px;
    border: 1px solid #f6f6f6;
    width: 25px;
    height: 25px;
}

.project__image {
    width: 100%;
    object-fit: contain;
}

.stack__image {
    border-radius: 25px;
    border: 1px solid transparent;
    width: 50px;
    height: 50px;
}


.avatar__image {
    border-radius: 50px;
    border: 1px solid transparent;
    width: 100px;
    height: 100px;
}


/* Extras */
@media screen and (min-width: 1024px) {
    .mb-16 {
        margin-bottom: 3rem;
    }
}